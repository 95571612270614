/*External dependencies*/
import update from 'immutability-helper';

/* Local dependencies */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { setLocalStorage } from '../../../common/utils/helpers';
import { mockUsers } from '../mockData';
import { initialClientActions, LoginAction, LoginActionTypes, LoginSteps, UserLocations } from './action';

export interface LoginState {
  user: boolean;
  email: string;
  emailError: string;
  password: string;
  repeatPassword?: string;
  passwordError: string;
  error: Error;
  loginStep?: LoginSteps;
  currentUser?: any;
  currentUserSession?: any;
  loginSuccess: any;
  client?: ApolloClient<NormalizedCacheObject>;
  session?: any;
  loading?: boolean;
  id: '';
  lat: number;
  lon: number;
  isOpen: boolean;
  isHomescreenOpen: boolean;
  isPanelOpen: boolean;
  isLoading: boolean;
  confirmCode?: string;
  newPassword?: string;
  isLoggedIn?: boolean;
  result?: string;
}

export const initialState: LoginState = {
  user: false,
  isOpen: false,
  loginStep: LoginSteps.INITIAL_FORM,
  error: null,
  isHomescreenOpen: false,
  isPanelOpen: true,
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  newPassword: '',
  isLoading: false,
  id: '',
  lat: 0,
  lon: 0,
  loginSuccess: null,
  //   isLoggedIn: !!savedSession,
  //   session: savedSession,
};

const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const passwordRegEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

export function authReducer(state = initialState, action: LoginAction) {
  switch (action.type) {
    case LoginActionTypes.SIGNUP_EMAIL_REQUEST:
      if (action.email.match(emailRegEx)) {
        return update(state, {
          $unset: ['error'],
          loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
          email: { $set: action.email },
        });
      }
      return update(state, {});

    case LoginActionTypes.SIGNUP_PASSWORD_REQUEST:
      if (action.password == action.passwordRepeat) {
        return update(state, {
          $unset: ['error'],
          password: { $set: action.password },
          repeatPassword: { $set: action.passwordRepeat },
          loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
        });
      }

      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
      });

    case LoginActionTypes.SIGNUP_SUCCEEDED:
      return update(state, {
        loginStep: { $set: LoginSteps.LOGIN_FORM },
        loading: { $set: false },
      });

    case LoginActionTypes.SIGNUP_FAILED:
      return update(state, {
        error: { $set: action.error },
        loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
      });

    case LoginActionTypes.SIGNUP_CONFIRM_CODE:
      if (action.confirmCode) {
        return update(state, {
          $unset: ['error'],
          loading: { $set: true },
        });
      }
      return update(state, {});

    case LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED:
      return update(state, {
        error: { $set: action.error },
        loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
        loading: { $set: false },
      });

    case LoginActionTypes.LOGIN_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });

    case LoginActionTypes.LOGIN_SUCCEEDED:
      const { result } = action;
      setLocalStorage('userSession', action.result);

      return update(state, {
        isOpen: { $set: false },
        user: { $set: true },
        // loginSuccess: { $set: result },
        session: { $set: result },
        // isLoggedIn: { $set: true },
        isLoading: { $set: false },
      });

    case LoginActionTypes.LOGIN_FAILED:
      return update(state, {
        loading: { $set: false },
        loginStep: { $set: LoginSteps.LOGIN_FORM },
        error: { $set: action.error },
      });

    case LoginActionTypes.FORGOT_PASSWORD_REQUEST:
      if (action.email.match(emailRegEx)) {
        return update(state, {
          $unset: ['error'],
          email: { $set: action.email },
          loginStep: { $set: LoginSteps.SEND_CODE_FOR_NEW_PASSWORD_MODAL },
        });
      }

      return update(state, {});

    case LoginActionTypes.FORGOT_PASSWORD_FAILED:
      return update(state, {
        error: { $set: action.error },
        loginStep: { $set: LoginSteps.FORGOT_PASSWORD_MODAL },
      });

    case LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST:
      return update(state, {
        confirmCode: { $set: action.confirmCode },
        loginStep: { $set: LoginSteps.SET_NEW_PASSWORD_FORM },
      });

    case LoginActionTypes.SET_NEW_PASSWORD_REQUEST:
      const userNewPassword = mockUsers.find((mockUser) => mockUser.email === action.email);

      if (action.newPassword.match(passwordRegEx) && userNewPassword) {
        setLocalStorage('authToken', userNewPassword.email);
        return update(state, {
          $unset: ['error'],
          newPassword: { $set: action.newPassword },
          loading: { $set: true },
        });
      }

      return update(state, {});

    case LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED:
      return update(state, {
        isLoggedIn: { $set: true },
        isOpen: { $set: false },
      });

    case LoginActionTypes.SIGN_OUT_REQUEST:
      //   removeItemFromLocalStorage('userSession');
      return update(state, {
        isLoggedIn: { $set: false },
        session: { $set: null },
        user: { $set: false },
        loginStep: { $set: LoginSteps.INITIAL_FORM },
      });

    case LoginActionTypes.SET_STEP:
      return update(state, {
        loginStep: { $set: action.step },
      });

    case LoginActionTypes.LOGIN_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.LOGIN_FORM },
      });

    case LoginActionTypes.INITIAL_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.INITIAL_FORM },
      });

    case initialClientActions.INITIAL_CLIENT_REQUEST:
      return update(state, {
        user: { $set: false },
        loading: { $set: true },
      });

    case initialClientActions.INITIAL_CLIENT_SUCCESS:
      const { session } = action;
      const emailAttribute = action.session.find((attr) => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      const valueAttribute = action.session.find((attr) => attr.Name === 'sub');
      const value = valueAttribute ? valueAttribute.Value : null;

      return update(state, {
        session: { $set: session },
        email: { $set: emailValue },
        user: { $set: true },
        loading: { $set: false },
        id: { $set: value },
      });

    case initialClientActions.INITIAL_CLIENT_ERROR:
      return update(state, {
        user: { $set: false },
        loading: { $set: false },
        error: { $set: action.error },
      });

    case LoginActionTypes.EMAIL_REGISTRATION_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_EMAIL_FORM },
      });

    case LoginActionTypes.CODE_REGISTRATION_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
      });

    case LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.SEND_CODE_FOR_NEW_PASSWORD_MODAL },
      });

    case LoginActionTypes.OPEN_MODAL:
      return update(state, {
        isOpen: { $set: true },
      });

    case LoginActionTypes.CLOSE_MODAL:
      return update(state, {
        isOpen: { $set: false },
      });

    case LoginActionTypes.OPEN_HOMESCREEN:
      return update(state, {
        isHomescreenOpen: { $set: true },
      });

    case LoginActionTypes.CLOSE_HOMESCREEN:
      return update(state, {
        isHomescreenOpen: { $set: false },
      });

    case LoginActionTypes.OPEN_PANEL:
      return update(state, {
        isPanelOpen: { $set: true },
      });

    case LoginActionTypes.CLOSE_PANEL:
      return update(state, {
        isPanelOpen: { $set: false },
      });

    case LoginActionTypes.FORGOT_PASSWORD_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.FORGOT_PASSWORD_MODAL },
      });

    case UserLocations.SET_LAT:
      return update(state, {
        lat: { $set: action.payload },
      });
    case UserLocations.SET_LON:
      return update(state, {
        lon: { $set: action.payload },
      });
    case LoginActionTypes.UPDATE_USER_POINTS:
      return update(state, {
        currentUser: {
          $set: {
            ...state.currentUser,
            points: action.payload, // Update points in the currentUser object
          },
        },
      });
    default:
      return state;
  }
}
