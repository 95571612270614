/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import { authReducer } from '../components/webApp/redux/reducer';
import {
  forgotPasswordEpic,
  initialUserEpic,
  setNewPasswordEpic,
  signInEpic,
  signOutEpic,
  signUpConfirmCodeEpic,
  signUpEpic,
} from '../components/webApp/redux/epics';
import getDeviceReducer from '../components/new_features/devices/getDevice/redux/reducer';
import listDeviceReducer from '../components/new_features/devices/listDevices/redux/reducer';
import { listDeviceEpic } from '../components/new_features/devices/listDevices/redux/epic';
import { getUserEpic } from '../components/new_features/users/getUser/redux/epic';
import getUserReducer from '../components/new_features/users/getUser/redux/reducer';
import { getDeviceEpic } from '../components/new_features/devices/getDevice/redux/epic';
import { approveDeviceEpic } from '../components/new_features/devices/approveDevice/redux/epic';
import approveDeviceReducer from '../components/new_features/devices/approveDevice/redux/reducer';
import { createDeviceEpic } from '../components/new_features/devices/createDevice/redux/epic';
import createDeviceReducer from '../components/new_features/devices/createDevice/redux/reducer';
import { searchDeviceEpic } from '../components/new_features/devices/searchDevice/redux/epic';
import searchDeviceReducer from '../components/new_features/devices/searchDevice/redux/reducer';
import finikMapReducer from '../components/webApp/finikMap/redux/reducer';
import listUsersReducer from '../components/new_features/users/listUsers/redux/reducer';
import { listUsersEpic } from '../components/new_features/users/listUsers/redux/epic';
import updateDeviceReducer from '../components/new_features/devices/updateDevice/redux/reducer';
import { updateDeviceEpic } from '../components/new_features/devices/updateDevice/redux/epic';
import updateUsersReducer from '../components/new_features/users/updateUser/redux/reducer';
import { updateUserEpic } from '../components/new_features/users/updateUser/redux/epic';
import deleteDeviceReducer from '../components/new_features/devices/deleteDevice/redux/reducer';
import { deleteDeviceEpic } from '../components/new_features/devices/deleteDevice/redux/epic';

const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmCodeEpic,
  signInEpic,
  forgotPasswordEpic,
  setNewPasswordEpic,
  signOutEpic,
  listDeviceEpic,
  getDeviceEpic,
  approveDeviceEpic,
  createDeviceEpic,
  updateDeviceEpic,
  deleteDeviceEpic,
  getUserEpic,
  listUsersEpic,
  updateUserEpic,
  searchDeviceEpic,
  initialUserEpic
);

const rootReducer = combineReducers({
  authReducer,
  getUserReducer,
  listUsersReducer,
  updateUsersReducer,
  getDeviceReducer,
  listDeviceReducer,
  approveDeviceReducer,
  createDeviceReducer,
  updateDeviceReducer,
  deleteDeviceReducer,
  searchDeviceReducer,
  finikMapReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
