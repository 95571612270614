export enum DeleteDeviceActionType {
  DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
  DELETE__DEVICE_SUCCESS = 'DELETE__DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR',
  OPEN_DELETE_DEVICE = 'OPEN_DELETE_DEVICE',
  CLOSE_DELETE_DEVICE = 'CLOSE_DELETE_DEVICE',
}

export interface DeleteDeviceRequestAction {
  type: DeleteDeviceActionType.DELETE_DEVICE_REQUEST;
  payload: { id: string };
}

export interface DeleteDeviceSuccessAction {
  type: DeleteDeviceActionType.DELETE__DEVICE_SUCCESS;
  statusMessage: string;
}

export interface DeleteDeviceErrorAction {
  type: DeleteDeviceActionType.DELETE_DEVICE_ERROR;
  error: Error;
}

export interface OpenDeleteDeviceModalAction {
  type: DeleteDeviceActionType.OPEN_DELETE_DEVICE;
}

export interface CloseDeleteDeviceModalAction {
  type: DeleteDeviceActionType.CLOSE_DELETE_DEVICE;
}

export function deleteDeviceRequest(id: string): DeleteDeviceRequestAction {
  return {
    type: DeleteDeviceActionType.DELETE_DEVICE_REQUEST,
    payload: { id },
  };
}

export function deleteDeviceSuccess(statusMessage: string): DeleteDeviceSuccessAction {
  return {
    type: DeleteDeviceActionType.DELETE__DEVICE_SUCCESS,
    statusMessage,
  };
}

export function deleteDeviceError(error: Error): DeleteDeviceErrorAction {
  return {
    type: DeleteDeviceActionType.DELETE_DEVICE_ERROR,
    error,
  };
}

export function openDeleteDeviceModal(): OpenDeleteDeviceModalAction {
  return {
    type: DeleteDeviceActionType.OPEN_DELETE_DEVICE,
  };
}

export function closeDeleteDeviceModal(): CloseDeleteDeviceModalAction {
  return {
    type: DeleteDeviceActionType.CLOSE_DELETE_DEVICE,
  };
}

export type DeleteDeviceActions =
  | DeleteDeviceRequestAction
  | DeleteDeviceSuccessAction
  | DeleteDeviceErrorAction
  | OpenDeleteDeviceModalAction
  | CloseDeleteDeviceModalAction;
