import update from 'immutability-helper';
import { GetDeviceActions, GetDeviceActionTypes } from './action';

export interface GetDeviceState {
  loading: boolean;
  error: null | Error;
  deviceData: any;
  openPanel: boolean;
}

export const initialGetDeviceStatus: GetDeviceState = {
  loading: false,
  error: null,
  deviceData: null,
  openPanel: false,
};

export default function getDeviceReducer(state = initialGetDeviceStatus, action: GetDeviceActions) {
  switch (action.type) {
    case GetDeviceActionTypes.GET_DEVICES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case GetDeviceActionTypes.GET_DEVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        deviceData: { $set: action.payload },
      });
    case GetDeviceActionTypes.GET_DEVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case GetDeviceActionTypes.GET_DEVICE_OPEN_PANEL:
      return update(state, {
        openPanel: { $set: action.isOpen },
      });
    default:
      return state;
  }
}
