import { DeleteDeviceActionType, DeleteDeviceActions } from './action';

export interface DeleteDeviceState {
  loading: boolean;
  error: string | null;
  statusMessage: string | null;
  openDeleteDeviceModal: boolean;
}

const initialState: DeleteDeviceState = {
  loading: false,
  error: null,
  statusMessage: null,
  openDeleteDeviceModal: false,
};

export default function deleteDeviceReducer(state = initialState, action: DeleteDeviceActions) {
  switch (action.type) {
    case DeleteDeviceActionType.DELETE_DEVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DeleteDeviceActionType.DELETE__DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMessage: action.statusMessage,
      };
    case DeleteDeviceActionType.DELETE_DEVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DeleteDeviceActionType.OPEN_DELETE_DEVICE:
      return {
        ...state,
        openDeleteDeviceModal: true,
      };
    case DeleteDeviceActionType.CLOSE_DELETE_DEVICE:
      return {
        ...state,
        openDeleteDeviceModal: false,
      };
    default:
      return state;
  }
}
