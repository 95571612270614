import update from 'immutability-helper';
import { FinikMapActions, FinikMapActionTypes } from './action';

export interface FinikMapState {
  openLeaderBoard: boolean;
  currentMarker: unknown;
  logoutOpenModal: boolean;
}

export const initialFinikMapStatus: FinikMapState = {
  openLeaderBoard: false,
  currentMarker: null,
  logoutOpenModal: false,
};

export default function finikMapReducer(state = initialFinikMapStatus, action: FinikMapActions) {
  switch (action.type) {
    case FinikMapActionTypes.OPEN_LEADER_BOARD:
      return update(state, {
        openLeaderBoard: { $set: true },
      });
    case FinikMapActionTypes.CLOSE_LEADER_BOARD:
      return update(state, {
        openLeaderBoard: { $set: false },
      });
    case FinikMapActionTypes.SET_CURRENT_MARKER:
      return update(state, {
        currentMarker: { $set: action.payload },
      });
    case FinikMapActionTypes.OPEN_USER_LOGOUT:
      return update(state, {
        logoutOpenModal: { $set: true },
      });
    case FinikMapActionTypes.CLOSE_USER_LOGOUT:
      return update(state, {
        logoutOpenModal: { $set: false },
      });
    default:
      return state;
  }
}
