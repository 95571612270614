/* External dependencies */
import { filter, Observable, switchMap } from 'rxjs';

/* Local dependencies */
import { setAuthenticatedClient } from '../../../../../client/graphql';
import { getUserQuery } from './query';
import { GetUserActions, GetUserActionTypes, getUserError, GetUserRequestAction, getUserSuccess } from './action';

export function getUserEpic(action$): Observable<GetUserActions> {
  return action$.pipe(
    filter((action: GetUserActions) => action.type === GetUserActionTypes.GET_USER_REQUEST),
    switchMap(({ id }: GetUserRequestAction) => getUser(id).then(getUserSuccess).catch(getUserError)),
  );
}

export async function getUser(input) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { getUser: user },
  } = await graphQLClient.query({
    query: getUserQuery,
    variables: { input: { id: input } },
  });

  return user;
}
