/*External dependencies */
import CognitoClient from '@mancho.devs/cognito';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { filter, mergeMap, switchMap, catchError } from 'rxjs/operators';
/*Local dependencies */
import {
  codeRegistrationForm,
  LoginAction,
  LoginActionTypes,
  SignupConfirmCode,
  signupFailed,
  SignupPasswordRequest,
  signupSucceeded,
  ForgotPassword,
  forgotPasswordFailed,
  SetNewPassword,
  sendCodeForNewPasswordModal,
  signOutSucceeded,
  SignOutRequest,
  signupConfirmCodeFailed,
  LoginRequest,
  loginSucceeded,
  loginFailed,
  initialClientSuccess,
  initialClientError,
  initialClientActions,
} from './action';
import { getSession } from '../../../client/graphql';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_PASSWORD_REQUEST),
    switchMap(({ email, password }: SignupPasswordRequest) =>
      cognitoClient.signUp(email, password).then(codeRegistrationForm).catch(signupFailed),
    ),
  );
}

export function signUpConfirmCodeEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_CONFIRM_CODE),
    switchMap(({ email, confirmCode }: SignupConfirmCode) =>
      cognitoClient.signUpConfirmCode(email, confirmCode).then(signupSucceeded).catch(signupConfirmCodeFailed),
    ),
  );
}

export function signInEpic(action$: Observable<LoginAction>): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.LOGIN_REQUEST),
    mergeMap((action: LoginRequest) =>
      from(cognitoClient.signIn(action.email, action.password)).pipe(
        mergeMap((loginResponse) =>
          from(getSession()).pipe(
            mergeMap((session) => {
              return from([loginSucceeded(loginResponse), initialClientSuccess(session)]);
            }),
            catchError((sessionError) => {
              return of(loginSucceeded(loginResponse), initialClientError(sessionError));
            }),
          ),
        ),
        catchError((error) => {
          return of(loginFailed(error));
        }),
      ),
    ),
  );
}

export function forgotPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.FORGOT_PASSWORD_REQUEST),
    switchMap(({ email }: ForgotPassword) =>
      cognitoClient.forgotPassword(email).then(sendCodeForNewPasswordModal).catch(forgotPasswordFailed),
    ),
  );
}

export function setNewPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.SET_NEW_PASSWORD_REQUEST),
    switchMap(({ email, confirmCode, newPassword }: SetNewPassword) =>
      cognitoClient.confirmPassword(email, confirmCode, newPassword).then(loginSucceeded).catch(forgotPasswordFailed),
    ),
  );
}

export function signOutEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.SIGN_OUT_REQUEST),
    switchMap(({}: SignOutRequest) => cognitoClient.signOut().then(signOutSucceeded)),
  );
}

export function initialUserEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === initialClientActions.INITIAL_CLIENT_REQUEST),
    switchMap(({}) => getSession().then(initialClientSuccess).catch(initialClientError)),
  );
}
