import update from 'immutability-helper';
import { ApproveDeviceActionTypes, ApproveDeviceActions } from './action';

export interface ApproveDeviceState {
  loading: boolean;
  error: null | Error;
  approvedDevice: any;
  successApproveNotification: boolean;
}

export const initialCreateDeviceStatus: ApproveDeviceState = {
  loading: false,
  error: null,
  approvedDevice: null,
  successApproveNotification: false,
};

export default function approveDeviceReducer(state = initialCreateDeviceStatus, action: ApproveDeviceActions) {
  switch (action.type) {
    case ApproveDeviceActionTypes.APPROVE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
        approvedDevice: { $set: null },
      });
    case ApproveDeviceActionTypes.APPROVE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        approvedDevice: { $set: action.payload },
        successApproveNotification: { $set: true },
      });
    case ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case ApproveDeviceActionTypes.SET_SUCCESS_NOTIFICATION:
      return update(state, {
        successApproveNotification: { $set: action.payload },
      });
    case ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR_CLEAR:
      return update(state, {
        $unset: ['error'],
      });
    default:
      return state;
  }
}
