import { Observable, from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { setAuthenticatedClient } from '../../../../../client/graphql';
import {
  DeleteDeviceActionType,
  DeleteDeviceActions,
  DeleteDeviceRequestAction,
  deleteDeviceError,
  deleteDeviceSuccess,
} from './action';
import { deleteDeviceMutation } from './query';

export function deleteDeviceEpic(action$): Observable<DeleteDeviceActions> {
  return action$.pipe(
    filter((action: DeleteDeviceActions) => action.type === DeleteDeviceActionType.DELETE_DEVICE_REQUEST),
    switchMap(({ updatedFields }: DeleteDeviceRequestAction) => {
      console.log('Epic received updatedFields:', updatedFields);
      return deleteDevice(updatedFields).then(deleteDeviceSuccess).catch(deleteDeviceError);
    }),
  );
}

export async function deleteDevice(input: { id: string }) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { deleteDevice: device },
  } = await graphQLClient.mutate({
    mutation: deleteDeviceMutation,
    variables: { input },
  });

  return device;
}
