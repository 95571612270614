/* External dependencies */
import { filter, Observable, switchMap } from 'rxjs';

/* Local dependencies */
import { setAnonymousClient } from '../../../../../client/graphql';
import {
  GetDeviceActions,
  GetDeviceActionTypes,
  getDeviceError,
  GetDeviceRequestAction,
  getDeviceSuccess,
} from './action';
import { getDeviceMutation } from './query';

export function getDeviceEpic(action$): Observable<GetDeviceActions> {
  return action$.pipe(
    filter((action: GetDeviceActions) => action.type === GetDeviceActionTypes.GET_DEVICES_REQUEST),
    switchMap(({ id }: GetDeviceRequestAction) => getDevice(id).then(getDeviceSuccess).catch(getDeviceError)),
  );
}

export async function getDevice(input) {
  const graphQLClient = await setAnonymousClient();

  const {
    data: { getDevice: device },
  } = await graphQLClient.mutate({
    mutation: getDeviceMutation,
    variables: { input: { id: input } },
  });

  return device;
}
