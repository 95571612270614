import update from 'immutability-helper';
import { CreateDeviceAction, CreateDeviceActionTypes } from './action';

export interface CreateDeviceState {
  isLoading: boolean;
  error: null | Error;
  coordinates: unknown;
  locationData: unknown;
  successNotification: boolean;
  notes: string;
  openCreateDeviceModal: boolean;
}

export const initialCreateDeviceStatus: CreateDeviceState = {
  isLoading: false,
  error: null,
  coordinates: null,
  locationData: null,
  openCreateDeviceModal: false,
  successNotification: false,
  notes: '',
};
export default function createDeviceReducer(state = initialCreateDeviceStatus, action: CreateDeviceAction) {
  switch (action.type) {
    case CreateDeviceActionTypes.CREATE_DEVICES_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        openCreateDeviceModal: { $set: false },
        successNotification: { $set: true },
      });
    case CreateDeviceActionTypes.SET_SUCCESS_NOTIFICATION:
      return update(state, {
        successNotification: { $set: action.payload },
      });
    case CreateDeviceActionTypes.CREATE_DEVICES_ERROR:
      console.log('Reducer received error', action.error);
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error },
      });
    case CreateDeviceActionTypes.SAVE_COORDINATES:
      return update(state, {
        coordinates: { $set: action.payload },
      });
    case CreateDeviceActionTypes.SAVE_LOCATION_DATA:
      return update(state, {
        locationData: { $set: action.payload },
      });
    case CreateDeviceActionTypes.NOTES:
      return update(state, {
        notes: { $set: action.notes },
      });
    case CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL:
      return update(state, {
        openCreateDeviceModal: { $set: true },
      });
    case CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL:
      return update(state, {
        openCreateDeviceModal: { $set: false },
      });
	  case CreateDeviceActionTypes.CREATE_DEVICE_ERROR_CLEAR:
		return update(state, {
		  $unset: ['error'],
		});
    default:
      return state;
  }
}
