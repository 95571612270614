export enum LoginActionTypes {
  INITIAL_FORM = 'INITIAL_FORM',
  LOGIN_FORM = 'LOGIN_FORM',
  FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM',
  SEND_CODE_FOR_NEW_PASSWORD_FORM = 'SEND_CODE_FOR_NEW_PASSWORD_FORM',
  SET_STEP = 'SET_STEP',
  DEFAULT_ACTION_TYPE = 'DEFAULT_ACTION_TYPE',
  SIGNUP_EMAIL_REQUEST = 'SIGNUP_EMAIL_REQUEST',
  SIGNUP_PASSWORD_REQUEST = 'SIGNUP_PASSWORD_REQUEST',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  SIGNUP_CONFIRM_CODE_FAILED = 'SIGNUP_CONFIRM_CODE_FAILED',
  SIGNUP_CONFIRM_CODE = 'SIGNUP_CONFIRM_CODE',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
  LOGIN_FAILED = 'LOGIN_FAILED',
  SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
  SEND_CODE_FOR_NEW_PASSWORD_REQUEST = 'SEND_CODE_FOR_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCEEDED = 'SET_NEW_PASSWORD_SUCCEEDED',
  EMAIL_REGISTRATION_FORM = 'EMAIL_REGISTRATION_FORM',
  PASSWORD_REGISTRATION_FORM = 'PASSWORD_REGISTRATION_FORM',
  CODE_REGISTRATION_FORM = 'CODE_REGISTRATION_FORM',
  OPEN_HOMESCREEN = 'OPEN_HOMESCREEN',
  CLOSE_HOMESCREEN = 'CLOSE_HOMESCREEN',
  OPEN_PANEL = 'OPEN_PANEL',
  CLOSE_PANEL = 'CLOSE_PANEL',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_CURRENT_MARKER = 'SET_CURRENT_MARKER',
  UPDATE_USER_POINTS = 'UPDATE_USER_POINTS',
}

export enum initialClientActions {
  INITIAL_CLIENT_REQUEST = 'INITIAL_CLIENT_REQUEST',
  INITIAL_CLIENT_SUCCESS = 'INITIAL_CLIENT_SUCCESS',
  INITIAL_CLIENT_ERROR = 'INITIAL_CLIENT_ERROR',
}

export enum LoginSteps {
  INITIAL_FORM = 'INITIAL_FORM',
  REGISTRATION_EMAIL_FORM = 'REGISTRATION_EMAIL_FORM',
  REGISTRATION_PASSWORD_FORM = 'REGISTRATION_PASSWORD_FORM',
  REGISTRATION_CODE_FORM = 'REGISTRATION_CODE_FORM',
  LOGIN_FORM = 'LOGIN_FORM',
  FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL',
  SEND_CODE_FOR_NEW_PASSWORD_MODAL = 'SEND_CODE_FOR_NEW_PASSWORD_MODAL',
  SET_NEW_PASSWORD_FORM = 'SET_NEW_PASSWORD_FORM',
}

export enum EnterCredentials {
  LOGIN_SET_EMAIL = 'LOGIN_SET_EMAIL',
  LOGIN_SET_PASSWORD = 'LOGIN_SET_PASSWORD',
  LOGIN_SET_NEW_PASSWORD = 'LOGIN_SET_NEW_PASSWORD',
  LOGIN_SET_OTP = 'LOGIN_SET_OTP',
}

export enum UserLocations {
  SET_LAT = 'SET_LAT',
  SET_LON = 'SET_LON',
}

export interface UpdateUserPoints {
  type: LoginActionTypes.UPDATE_USER_POINTS;
  payload: number;
}

export interface SetLatAction {
  type: UserLocations.SET_LAT;
  payload: number;
}

export interface SetLonAction {
  type: UserLocations.SET_LON;
  payload: number;
}

export interface InitialRequestAction {
  type: initialClientActions.INITIAL_CLIENT_REQUEST;
}

export interface InitialSuccessAction {
  type: initialClientActions.INITIAL_CLIENT_SUCCESS;
  session: any;
}

export interface InitialErrorAction {
  type: initialClientActions.INITIAL_CLIENT_ERROR;
  error: Error;
}

export const initialClientRequest = (): InitialRequestAction => ({
  type: initialClientActions.INITIAL_CLIENT_REQUEST,
});

export const initialClientSuccess = (session: any): InitialSuccessAction => ({
  type: initialClientActions.INITIAL_CLIENT_SUCCESS,
  session,
});

export const initialClientError = (error: Error): InitialErrorAction => ({
  type: initialClientActions.INITIAL_CLIENT_ERROR,
  error,
});

export interface EmptyAction {
  type: LoginActionTypes.DEFAULT_ACTION_TYPE;
}
export interface SignupEmailRequest {
  type: LoginActionTypes.SIGNUP_EMAIL_REQUEST;
  email: string;
}
export interface SignupPasswordRequest {
  type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST;
  email: string;
  password: string;
  passwordRepeat: string;
}
export interface SignupConfirmCode {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE;
  email: string;
  confirmCode: string;
}
export interface SignupSucceeded {
  type: LoginActionTypes.SIGNUP_SUCCEEDED;
  user: any;
}
export interface SignupFailed {
  type: LoginActionTypes.SIGNUP_FAILED;
  error: Error;
}
export interface SignupConfirmCodeFailed {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED;
  error: Error;
}
export interface LoginRequest {
  type: LoginActionTypes.LOGIN_REQUEST;
  email: string;
  password: string;
}
export interface LoginSucceeded {
  type: LoginActionTypes.LOGIN_SUCCEEDED;
  result: {};
}
export interface LoginFailed {
  type: LoginActionTypes.LOGIN_FAILED;
  error: Error;
}
export interface ForgotPassword {
  type: LoginActionTypes.FORGOT_PASSWORD_REQUEST;
  email: string;
}
export interface ForgotPasswordFailed {
  type: LoginActionTypes.FORGOT_PASSWORD_FAILED;
  error: Error;
}
export interface SendCodeForNewPassword {
  type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST;
  confirmCode: string;
}
export interface SetNewPassword {
  type: LoginActionTypes.SET_NEW_PASSWORD_REQUEST;
  email: string;
  confirmCode: string;
  newPassword: string;
}
export interface SetNewPasswordSucceeded {
  type: LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED;
}
export interface SignOutRequest {
  type: LoginActionTypes.SIGN_OUT_REQUEST;
}
export interface SignOutSucceeded {
  type: LoginActionTypes.SIGN_OUT_SUCCEEDED;
  //   result: {};
}
export interface SetStep {
  type: LoginActionTypes.SET_STEP;
  step: LoginSteps;
}

export interface InitialForm {
  type: LoginActionTypes.INITIAL_FORM;
}
export interface EmailRegistrationForm {
  type: LoginActionTypes.EMAIL_REGISTRATION_FORM;
}
export interface PasswordRegistrationForm {
  type: LoginActionTypes.PASSWORD_REGISTRATION_FORM;
}
export interface CodeRegistrationForm {
  type: LoginActionTypes.CODE_REGISTRATION_FORM;
  result: {};
}
export interface LoginForm {
  type: LoginActionTypes.LOGIN_FORM;
}
export interface ForgotPasswordForm {
  type: LoginActionTypes.FORGOT_PASSWORD_FORM;
}
export interface SendCodeForNewPasswordModal {
  type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM;
  result: {};
}
// open/close modal && panel
export interface OpenModal {
  type: LoginActionTypes.OPEN_MODAL;
}
export interface CloseModal {
  type: LoginActionTypes.CLOSE_MODAL;
}
export interface OpenHomescreen {
  type: LoginActionTypes.OPEN_HOMESCREEN;
}
export interface CloseHomescreen {
  type: LoginActionTypes.CLOSE_HOMESCREEN;
}
export interface OpenPanel {
  type: LoginActionTypes.OPEN_PANEL;
}
export interface ClosePanel {
  type: LoginActionTypes.CLOSE_PANEL;
}
export interface SetCurrentMarker {
  type: LoginActionTypes.SET_CURRENT_MARKER;
  payload: any;
}

export const updateUserPoints = (points: number): UpdateUserPoints => ({
  type: LoginActionTypes.UPDATE_USER_POINTS,
  payload: points,
});

export const setLat = (payload: number): SetLatAction => ({
  type: UserLocations.SET_LAT,
  payload,
});

export const setLon = (payload: number): SetLonAction => ({
  type: UserLocations.SET_LON,
  payload,
});

export function signupEmailRequest(email: string): SignupEmailRequest {
  return {
    type: LoginActionTypes.SIGNUP_EMAIL_REQUEST,
    email,
  };
}
export function signupPasswordRequest(email: string, password: string, passwordRepeat: string): SignupPasswordRequest {
  return {
    type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST,
    email,
    password,
    passwordRepeat,
  };
}
export function signupConfirmCode(email: string, confirmCode: string): SignupConfirmCode {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE,
    email,
    confirmCode,
  };
}
export function signupSucceeded(user: any): SignupSucceeded {
  console.log('result from action', user);
  return {
    type: LoginActionTypes.SIGNUP_SUCCEEDED,
    user,
  };
}
export function signupFailed(error: Error): SignupFailed {
  return {
    type: LoginActionTypes.SIGNUP_FAILED,
    error,
  };
}
export function signupConfirmCodeFailed(error: Error): SignupConfirmCodeFailed {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED,
    error,
  };
}
export function loginRequest(email: string, password: string): LoginRequest {
  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    email,
    password,
  };
}
export function loginSucceeded(result: {}): LoginSucceeded {
  return {
    type: LoginActionTypes.LOGIN_SUCCEEDED,
    result,
  };
}
export function loginFailed(error: Error): LoginFailed {
  return {
    type: LoginActionTypes.LOGIN_FAILED,
    error,
  };
}
export function forgotPassword(email: string): ForgotPassword {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_REQUEST,
    email,
  };
}
export function forgotPasswordFailed(error: Error): ForgotPasswordFailed {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FAILED,
    error,
  };
}
export function sendCodeForNewPassword(confirmCode: string): SendCodeForNewPassword {
  return {
    type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST,
    confirmCode,
  };
}
export function setNewPassword(email: string, confirmCode: string, newPassword: string): SetNewPassword {
  return {
    type: LoginActionTypes.SET_NEW_PASSWORD_REQUEST,
    email,
    confirmCode,
    newPassword,
  };
}
export function setNewPasswordSucceeded(): SetNewPasswordSucceeded {
  return {
    type: LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED,
  };
}
export function signOutRequest(): SignOutRequest {
  return {
    type: LoginActionTypes.SIGN_OUT_REQUEST,
  };
}
export function signOutSucceeded(): SignOutSucceeded {
  return {
    type: LoginActionTypes.SIGN_OUT_SUCCEEDED,
  };
}
export function setStep(step: LoginSteps): SetStep {
  return {
    type: LoginActionTypes.SET_STEP,
    step,
  };
}

export function initialForm(): InitialForm {
  return {
    type: LoginActionTypes.INITIAL_FORM,
  };
}
export function emailRegistrationForm(): EmailRegistrationForm {
  return {
    type: LoginActionTypes.EMAIL_REGISTRATION_FORM,
  };
}
export function passwordRegistrationForm(): PasswordRegistrationForm {
  return {
    type: LoginActionTypes.PASSWORD_REGISTRATION_FORM,
  };
}
export function codeRegistrationForm(result: {}): CodeRegistrationForm {
  return {
    type: LoginActionTypes.CODE_REGISTRATION_FORM,
    result,
  };
}
export function loginForm(): LoginForm {
  return {
    type: LoginActionTypes.LOGIN_FORM,
  };
}
export function forgotPasswordForm(): ForgotPasswordForm {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FORM,
  };
}
export function sendCodeForNewPasswordModal(result: {}): SendCodeForNewPasswordModal {
  return {
    type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM,
    result,
  };
}
// close/open modal && homescreen
export function openModal(): OpenModal {
  return {
    type: LoginActionTypes.OPEN_MODAL,
  };
}
export function closeModal(): CloseModal {
  return {
    type: LoginActionTypes.CLOSE_MODAL,
  };
}
export function openHomescreen(): OpenHomescreen {
  return {
    type: LoginActionTypes.OPEN_HOMESCREEN,
  };
}

export function closeHomescreen(): CloseHomescreen {
  return {
    type: LoginActionTypes.CLOSE_HOMESCREEN,
  };
}
export function openPanel(): OpenPanel {
  return {
    type: LoginActionTypes.OPEN_PANEL,
  };
}

export function closePanel(): ClosePanel {
  return {
    type: LoginActionTypes.CLOSE_PANEL,
  };
}

export const setCurrentMarker = (payload: any): SetCurrentMarker => ({
  type: LoginActionTypes.SET_CURRENT_MARKER,
  payload,
});

export type LoginAction =
  | LoginRequest
  | LoginSucceeded
  | LoginFailed
  | SignupEmailRequest
  | SignupPasswordRequest
  | SignupConfirmCode
  | SignupConfirmCodeFailed
  | SignupSucceeded
  | SignupFailed
  | ForgotPassword
  | ForgotPasswordForm
  | ForgotPasswordFailed
  | SendCodeForNewPassword
  | SetNewPassword
  | SetNewPasswordSucceeded
  | SignOutRequest
  | SignOutSucceeded
  | SetStep
  | InitialForm
  | LoginForm
  | EmailRegistrationForm
  | PasswordRegistrationForm
  | CodeRegistrationForm
  | OpenModal
  | CloseModal
  | OpenHomescreen
  | CloseHomescreen
  | OpenPanel
  | ClosePanel
  | SendCodeForNewPasswordModal
  | EmptyAction
  | SetCurrentMarker
  | InitialRequestAction
  | InitialSuccessAction
  | InitialErrorAction
  | SetLatAction
  | SetLonAction
  | UpdateUserPoints;
