import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../../client/graphql';
import {
  ListUsersAction,
  ListUsersActionTypes,
  ListUsersRequestAction,
  listUsersError,
  listUsersSuccess,
} from './action';
import { listUsersQuery } from './query';

export function listUsersEpic(action$): Observable<ListUsersAction> {
  return action$.pipe(
    filter((action: ListUsersAction) => action.type === ListUsersActionTypes.LIST_USERS_REQUEST),
    switchMap(({ filter }: ListUsersRequestAction) => listDevices(filter).then(listUsersSuccess).catch(listUsersError)),
  );
}

export async function listDevices({ filter }: any): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listUsers: { users, total },
    },
} = await graphQLClient.query({
	query: listUsersQuery,
    variables: {
		input: {
			filter,
		},
    },
});

return { total, users };
console.log('listUsers', users)
}
