export enum GetDeviceActionTypes {
	GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST',
	GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS',
	GET_DEVICES_ERROR = 'GET_DEVICES_ERROR',
	GET_DEVICE_OPEN_PANEL = 'GET_DEVICE_OPEN_PANEL',
  }
  
  export interface GetDeviceRequestAction {
	type: GetDeviceActionTypes.GET_DEVICES_REQUEST;
	id: string;
  }
  export interface GetDeviceSuccessAction {
	type: GetDeviceActionTypes.GET_DEVICES_SUCCESS;
	payload: any;
  }
  
  export interface GetDeviceErrorAction {
	type: GetDeviceActionTypes.GET_DEVICES_ERROR;
	error: Error;
  }
  
  export interface GetDeviceOpenPanelAction {
	type: GetDeviceActionTypes.GET_DEVICE_OPEN_PANEL;
	isOpen: boolean;
  }
  
  export type GetDeviceActions =
	| GetDeviceRequestAction
	| GetDeviceSuccessAction
	| GetDeviceErrorAction
	| GetDeviceOpenPanelAction;
  
  export function getDeviceRequest(id: string): GetDeviceRequestAction {
	return {
	  type: GetDeviceActionTypes.GET_DEVICES_REQUEST,
	  id,
	};
  }
  
  export function getDeviceSuccess(payload: any): GetDeviceSuccessAction {
	return {
	  type: GetDeviceActionTypes.GET_DEVICES_SUCCESS,
	  payload,
	};
  }
  
  export function getDeviceError(error: Error): GetDeviceErrorAction {
	return {
	  type: GetDeviceActionTypes.GET_DEVICES_ERROR,
	  error,
	};
  }
  
  export function getDeviceOpenPanel(isOpen: boolean): GetDeviceOpenPanelAction {
	return {
	  type: GetDeviceActionTypes.GET_DEVICE_OPEN_PANEL,
	  isOpen,
	};
  }
  