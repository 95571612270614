export enum FinikMapActionTypes {
  OPEN_LEADER_BOARD = 'OPEN_LEADER_BOARD',
  CLOSE_LEADER_BOARD = 'CLOSE_LEADER_BOARD',
  SET_CURRENT_MARKER = 'SET_CURRENT_MARKER',
  OPEN_USER_LOGOUT = 'OPEN_USER_LOGOUT',
  CLOSE_USER_LOGOUT = 'CLOSE_USER_LOGOUT',
  OPEN_SIDE_DRAWER = 'OPEN_SIDE_DRAWER',
  CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER',
}

export interface OpenLeaderBoardActions {
  type: FinikMapActionTypes.OPEN_LEADER_BOARD;
}

export interface CloseLeaderBoardActions {
  type: FinikMapActionTypes.CLOSE_LEADER_BOARD;
}

export interface OpenSideDrawerActions {
  type: FinikMapActionTypes.OPEN_SIDE_DRAWER;
}

export interface CloseSideDrawerActions {
  type: FinikMapActionTypes.CLOSE_SIDE_DRAWER;
}

export interface SetCurrentMarker {
  type: FinikMapActionTypes.SET_CURRENT_MARKER;
  payload: any;
}

export interface CloseUserLogoutActions {
  type: FinikMapActionTypes.CLOSE_USER_LOGOUT;
}

export interface OpenUserLogoutActions {
  type: FinikMapActionTypes.OPEN_USER_LOGOUT;
}

export type FinikMapActions =
  | OpenLeaderBoardActions
  | CloseLeaderBoardActions
  | SetCurrentMarker
  | CloseUserLogoutActions
  | OpenUserLogoutActions
  | OpenSideDrawerActions
  | CloseSideDrawerActions;

export const openLeaderBoard = (): OpenLeaderBoardActions => ({
  type: FinikMapActionTypes.OPEN_LEADER_BOARD,
});

export const closeLeaderBoard = (): CloseLeaderBoardActions => ({
  type: FinikMapActionTypes.CLOSE_LEADER_BOARD,
});

export const openUserLogout = (): OpenUserLogoutActions => ({
  type: FinikMapActionTypes.OPEN_USER_LOGOUT,
});

export const closeUserLogout = (): CloseUserLogoutActions => ({
  type: FinikMapActionTypes.CLOSE_USER_LOGOUT,
});

export const setCurrentMarker = (payload: any): SetCurrentMarker => ({
  type: FinikMapActionTypes.SET_CURRENT_MARKER,
  payload,
});
