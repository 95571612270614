export enum ListUsersActionTypes {
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',
  LIST_USERS_ERROR = 'LIST_USERS_ERROR',
}

export interface ListUsersRequestAction {
  type: ListUsersActionTypes.LIST_USERS_REQUEST;
  filter: any;
}

export interface ListUsersSuccessAction {
  type: ListUsersActionTypes.LIST_USERS_SUCCESS;
  users: any;
}

export interface ListUsersErrorAction {
  type: ListUsersActionTypes.LIST_USERS_ERROR;
  error: Error;
}

export type ListUsersAction = ListUsersRequestAction | ListUsersSuccessAction | ListUsersErrorAction;

export function listUsersRequest(filter: any): ListUsersRequestAction {
  return {
    type: ListUsersActionTypes.LIST_USERS_REQUEST,
    filter,
  };
}

export function listUsersError(error: Error): ListUsersErrorAction {
  return {
    type: ListUsersActionTypes.LIST_USERS_ERROR,
    error,
  };
}

export function listUsersSuccess(users: any): ListUsersSuccessAction {
  return {
    type: ListUsersActionTypes.LIST_USERS_SUCCESS,
    users,
  };
}
