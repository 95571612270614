/* External dependencies */
import { Observable } from "redux";
import { debounceTime, filter, switchMap } from "rxjs";

/* Local dependencies */
import { getAnonymousClient } from "../../../../../client/graphql";
import { listDevicesQuery } from "../../listDevices/redux/query";
import { SearchDeviceActionTypes, SearchDeviceActions, SearchDeviceRequestAction, searchDeviceError, searchDeviceSuccess } from "./action";

export function searchDeviceEpic(action$): Observable<SearchDeviceActions> {
    return action$.pipe(
        filter((action: SearchDeviceActions) => action.type === SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST),
        debounceTime(1000),
        switchMap(({ searchQuery }: SearchDeviceRequestAction) =>
            searchDevices(searchQuery).then(searchDeviceSuccess).catch(searchDeviceError),
        ),
    );
}

export async function searchDevices(query: string): Promise<any> {
    const graphQLClient = await getAnonymousClient();

    const {
        data: {
            listDevices: { devices },
        },
    } = await graphQLClient.query({
        query: listDevicesQuery,
        variables: {
            input: {
                query,
            },
        },
    });

    return devices;
}