export enum CreateDeviceActionTypes {
  CREATE_DEVICES_REQUEST = 'CREATE_DEVICES_REQUEST',
  CREATE_DEVICES_SUCCESS = 'CREATE_DEVICES_SUCCESS',
  CREATE_DEVICES_ERROR = 'CREATE_DEVICES_ERROR',
  SAVE_COORDINATES = 'SAVE_COORDINATES',
  SAVE_LOCATION_DATA = 'SAVE_LOCATION_DATA',
  NOTES = 'NOTES',
  OPEN_CREATE_DEVICE_MODAL = 'OPEN_CREATE_DEVICE_MODAL',
  CLOSE_CREATE_DEVICE_MODAL = 'CLOSE_CREATE_DEVICE_MODAL',
  SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION',
  CREATE_DEVICE_ERROR_CLEAR = 'CREATE_DEVICE_ERROR_CLEAR',
}

export interface SuccessNotificationAction {
  type: CreateDeviceActionTypes.SET_SUCCESS_NOTIFICATION;
  payload: any;
}

export interface OpenCreateDeviceModalActions {
  type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL;
}

export interface CloseCreateDeviceModalActions {
  type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL;
}

export interface CreateDeviceRequestAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_REQUEST;
  input: any;
}
export interface CreateDeviceSuccessAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS;
}

export interface CreateDeviceErrorAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_ERROR;
  error: Error;
}

export interface CreateDeviceErrorClearAction {
  type: CreateDeviceActionTypes.CREATE_DEVICE_ERROR_CLEAR;
}

export interface SaveCoordinates {
  type: CreateDeviceActionTypes.SAVE_COORDINATES;
  payload;
}

export interface SaveLocationData {
  type: CreateDeviceActionTypes.SAVE_LOCATION_DATA;
  payload;
}

export interface NotesAction {
  type: CreateDeviceActionTypes.NOTES;
  notes: string;
}

export type CreateDeviceAction =
  | CreateDeviceRequestAction
  | CreateDeviceSuccessAction
  | CreateDeviceErrorAction
  | CreateDeviceErrorClearAction
  | SaveCoordinates
  | SaveLocationData
  | NotesAction
  | OpenCreateDeviceModalActions
  | CloseCreateDeviceModalActions
  | SuccessNotificationAction;

export function createDeviceRequest(input: any): CreateDeviceRequestAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_REQUEST,
    input,
  };
}

export function createDeviceSuccess(): CreateDeviceSuccessAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS,
  };
}

export function createDeviceError(error: Error): CreateDeviceErrorAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_ERROR,
    error,
  };
}

export function saveCoordinates(payload): SaveCoordinates {
  return {
    type: CreateDeviceActionTypes.SAVE_COORDINATES,
    payload,
  };
}

export function saveLocationData(payload: any): SaveLocationData {
  return {
    type: CreateDeviceActionTypes.SAVE_LOCATION_DATA,
    payload,
  };
}

export function nameDevice(notes: string): NotesAction {
  return {
    type: CreateDeviceActionTypes.NOTES,
    notes,
  };
}

export const openCreateDeviceModal = (): OpenCreateDeviceModalActions => ({
  type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL,
});

export const closeCreateDeviceModal = (): CloseCreateDeviceModalActions => ({
  type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL,
});

export function setSuccessNotification(payload: any): SuccessNotificationAction {
  return {
    type: CreateDeviceActionTypes.SET_SUCCESS_NOTIFICATION,
    payload,
  };
}

export function setClearErrorCreateDevice(): CreateDeviceErrorClearAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICE_ERROR_CLEAR,
  };
}
