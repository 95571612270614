import { gql } from '@apollo/client';

export const listDevicesQuery = gql`
  query listDevices($input: ListDevicesInput!) {
    listDevices(input: $input) {
      devices {
        ... on Terminal {
          id
          __typename
          address
          approvalsCount
          location {
            lat
            lon
          }
          organization
          notes
          registrationDate
          status
          user {
            id
          }
        }
      }
      total
    }
  }
`;
